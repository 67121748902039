import firebase from 'firebase/app';
import { firebaseConfig } from '../config';
require('firebase/auth');
require('firebase/storage');
require('firebase/database');


const app = firebase.initializeApp(firebaseConfig)
export const auth = app.auth()

const createGoogleProvider = (): firebase.auth.AuthProvider => {
  let provider = new firebase.auth.GoogleAuthProvider()
  provider.addScope("email")
  provider.setCustomParameters({
    prompt: 'select_account',
  })
  return provider
}

const createFacebookProvider = (): firebase.auth.AuthProvider => {
  let provider = new firebase.auth.FacebookAuthProvider() // TODO setup scopes
  provider.addScope("public_profile")
  return provider;
}

export const GoogleAuthProvider = createGoogleProvider()
export const FacebookAuthProvider = createFacebookProvider()
