// tslint:disable
/**
 * Firmabil lekeplass API
 * A description of the API
 *
 * OpenAPI spec version: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    AdminPlayground,
    AdminPlaygroundFromJSON,
    AdminPlaygroundToJSON,
} from './';

/**
 * AdminPlaygroundList media type (default view)
 * @export
 * @interface AdminPlaygroundList
 */
export interface AdminPlaygroundList {
    /**
     * 
     * @type {number}
     * @memberof AdminPlaygroundList
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminPlaygroundList
     */
    offset?: number;
    /**
     * 
     * @type {Array<AdminPlayground>}
     * @memberof AdminPlaygroundList
     */
    playgrounds?: Array<AdminPlayground>;
}

export function AdminPlaygroundListFromJSON(json: any): AdminPlaygroundList {
    return {
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'playgrounds': !exists(json, 'playgrounds') ? undefined : (json['playgrounds'] as Array<any>).map(AdminPlaygroundFromJSON),
    };
}

export function AdminPlaygroundListToJSON(value?: AdminPlaygroundList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'limit': value.limit,
        'offset': value.offset,
        'playgrounds': value.playgrounds === undefined ? undefined : (value.playgrounds as Array<any>).map(AdminPlaygroundToJSON),
    };
}


