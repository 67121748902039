
export const firebaseConfig = {
  apiKey: "AIzaSyCudEfi3126qTrYUjajGAJM1-WvseR3vZ0",
  authDomain: "utoglek.firebaseapp.com",
  databaseURL: "https://utoglek.firebaseio.com",
  projectId: "utoglek",
  storageBucket: "utoglek.appspot.com",
  messagingSenderId: "571162560419"
};

const FIRMABIL_URL_PROD = "https://firmabil-dot-utoglek.appspot.com"
//const FIRMABIL_URL_LOCAL = "http://localhost:8080/"
const IMAGES_URL_PROD = "https://storage.googleapis.com/utoglek-public"
//const IMAGES_URL_PROD_LOCAL = "http://localhost:8080/uploads/"

export const FIRMABIL_URL = process.env.REACT_APP_API_URL || FIRMABIL_URL_PROD
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || IMAGES_URL_PROD
