import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FIRMABIL_URL } from "../config";
import firebase from '../firebase';
import { AdminApi, AdminPlayground, AdminPlaygroundList, Configuration, Playground } from '../firmabil-fetch';
import './PlaygroundsScreen.css';
import Paper from '@material-ui/core/Paper'
import { Grid, Button } from "@material-ui/core";


const listPlaygrounds = (token: string): Promise<AdminPlaygroundList> => new AdminApi(new Configuration({ basePath: FIRMABIL_URL, apiKey: "Bearer " + token })).adminListPlaygrounds({ limit: 1000, offset: 0 })


const PlaygroundListItem = ({ p }: { p: Playground }) => (
  <Paper onClick={() => window.location.pathname = "/playgrounds/" + p.id}>
    <h3 className="item-title">{p.title}</h3>
    <div>Location: {p.lat + "," + p.lon}</div>
    {// <Link to={{ pathname: "/playgrounds/" + p.id }}></Link>
    }
  </Paper>
)

const renderPlaygroundListItem = (p: Playground) => (
  <div key={p.id} className="list-item">
    <h3 className="item-title"><Link to={{ pathname: "/playgrounds/" + p.id }}>{p.title}</Link></h3>
    <div>Location: {p.lat + "," + p.lon}</div>
  </div>
)

interface Props { }

interface State {
  loading: boolean
  playgrounds: Array<AdminPlayground>
}

class PlaygroundsScreen extends Component<Props, State>{
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
      playgrounds: []
    }
  }

  fetchPlaygrounds() {
    if (firebase.auth.currentUser == null) return
    this.setState({
      loading: true,
    })
    firebase.auth.currentUser.getIdToken()
      .then(token => listPlaygrounds(token))
      .then(list => this.setState({
        loading: false,
        playgrounds: list.playgrounds || [],
      }))
  }

  componentDidMount() {
    this.fetchPlaygrounds()
  }

  render() {
    let { playgrounds, loading } = this.state

    return (
      <div>
        <div>Playgrounds screen</div>
        <div>{loading && "Loading playgrounds"}</div>
        <Grid style={{ flex: "grow" }} container spacing={0}>
          <Grid container style={{ margin: 0 }} spacing={8} justify="space-around" >
            {playgrounds.map(p => <Grid key={p.id} item xs={12} md={6} lg={4} xl={3}><PlaygroundListItem p={p} /> </Grid>)}
          </Grid>
        </Grid>

      </div>
    )
  }
}

export default PlaygroundsScreen
