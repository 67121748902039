// tslint:disable
/**
 * Firmabil lekeplass API
 * A description of the API
 *
 * OpenAPI spec version: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * Submission media type (default view)
 * @export
 * @interface Submission
 */
export interface Submission {
    /**
     * 
     * @type {string}
     * @memberof Submission
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Submission
     */
    imagePaths?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Submission
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof Submission
     */
    lon: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Submission
     */
    playgroundsContributedTo?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Submission
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Submission
     */
    userId: string;
}

export function SubmissionFromJSON(json: any): Submission {
    return {
        'id': json['id'],
        'imagePaths': !exists(json, 'image_paths') ? undefined : json['image_paths'],
        'lat': json['lat'],
        'lon': json['lon'],
        'playgroundsContributedTo': !exists(json, 'playgrounds_contributed_to') ? undefined : json['playgrounds_contributed_to'],
        'title': json['title'],
        'userId': json['user_id'],
    };
}

export function SubmissionToJSON(value?: Submission): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'image_paths': value.imagePaths,
        'lat': value.lat,
        'lon': value.lon,
        'playgrounds_contributed_to': value.playgroundsContributedTo,
        'title': value.title,
        'user_id': value.userId,
    };
}


