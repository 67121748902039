import React from 'react'
import { Image } from '.'
import { FIRMABIL_URL, IMAGES_URL } from '../../config';
import {
  AdminApi,
  AdminSubmission,
  Configuration
} from '../../firmabil-fetch';
import firebase from '../../firebase';

interface SubmissionProps {
  sub: AdminSubmission | null
  selectedPlaygroundID: string | null
  loading?: boolean
  loadingErr?: any
}

export default class SubmissionView extends React.Component<SubmissionProps> {
  render() {
    let { loading, loadingErr, sub, selectedPlaygroundID } = this.props;

    if (loading) return <div>Loading submission...</div>

    if (loadingErr) return (
      <div>Failed to load submission
        <pre>{loadingErr.toString()}</pre>
      </div>
    )

    if (sub == null || sub.id == null) return <div>No submission</div>

    let baseurl = IMAGES_URL + "/image/sub/w_300/" + sub.id + "/"

    const imagesRender = (sub.imagePaths || []).map(filename => {
      return (
        <Image
          onclick={() =>
            selectedPlaygroundID &&
            firebase.auth.currentUser &&
            firebase.auth.currentUser.getIdToken()
            .then(token => new AdminApi(new Configuration({ basePath: FIRMABIL_URL, apiKey: "Bearer " + token }))
            .adminAttachPlaygroundImage({
              id: selectedPlaygroundID || "",
              filename: filename || "",
              submissionId: sub!.id || "",
              published: true,
            }))
            .then(() => console.log("We published an image", "filename", filename))
            .catch((err) => console.error("something went wrong", err))
          }
          key={filename}
          src={baseurl + filename} />
      )
    })

    return (
      <div>
        <h3>{sub.title}</h3>

        <div style={{display: "flex", paddingLeft: 20, paddingRight: 20, paddingBottom: 20  }}>
          <div>
            <span className="info-span bold">Innsender: </span>
            <span className="info-span bold">Dato: </span>
            <span className="info-span bold">ID: </span>
            <span className="info-span bold">Koordinater: </span>
          </div>

          <div style={{flexGrow: 100, paddingLeft: 20}}>
            <span className="info-span">{sub.userId}</span>
            <span className="info-span">{sub.created!.toLocaleDateString("nb-NO")}</span>
            <span className="info-span">{sub.id}</span>
            <span className="info-span">{sub.lat},</span>
            <span className="info-span">{sub.lon}</span>
          </div>
        </div>

        <div>
          {imagesRender}
        </div>
      </div>
    )
  }
}
