import React, { Component } from 'react';
import { FIRMABIL_URL } from '../config';
import firebase from '../firebase'
import { Configuration, SubmissionApi, Submission } from '../firmabil-fetch';
import { Link } from 'react-router-dom';


interface Props {}
interface State{
  submissions: Array<Submission>
}

class MySubmissionList extends Component<Props,State> {
  constructor(props:any) {
    super(props)
    this.state = {
      submissions:[],
    }
  }
  componentDidMount() {
    if (firebase.auth.currentUser == null)  return
    firebase.auth.currentUser.getIdToken()
      .then(token => fetchMySubmissions(token))
      .then(subs => this.setState({
        submissions: subs.submissions || [],
      })).catch(err => {
        console.error("failed to fetch my subs",err)
      })
  }

  render() {
    let {submissions} = this.state;
    return (
      <div>
        <div>MY SUBMISSIONS YO!</div>
        {submissions.map(s => (
          <div key={s.id}>
            <Link to={"/my-submissions/"+s.id}><p>{s.title} ({s.id})</p></Link>
            <p>{s.lat + "," + s.lon}</p>
          </div>
        ))}
      </div>
    )
  }
}

const fetchMySubmissions = (token: string) => new SubmissionApi(new Configuration({
  basePath: FIRMABIL_URL,
  apiKey: "Bearer " + token,
})).submissionList()

export default MySubmissionList
