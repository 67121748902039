import React from 'react';


interface Props {
  submissionId: string | null
}
interface State { }

class MySubmissionDetail extends React.Component<Props, State> {
  render() {
    let { submissionId } = this.props;
    if (submissionId == null) return <div>No submission id :(</div>

    return (
      <div>

    <div>My Submission Detail {submissionId}</div>
    <pre>her kommer json</pre>
    </div>
    )
  }
}

export default MySubmissionDetail
