// tslint:disable
/**
 * Firmabil lekeplass API
 * A description of the API
 *
 * OpenAPI spec version: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface AdminImageModel
 */
export interface AdminImageModel {
    /**
     * 
     * @type {string}
     * @memberof AdminImageModel
     */
    filename?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminImageModel
     */
    published?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminImageModel
     */
    submissionId?: string;
}

export function AdminImageModelFromJSON(json: any): AdminImageModel {
    return {
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'published': !exists(json, 'published') ? undefined : json['published'],
        'submissionId': !exists(json, 'submission_id') ? undefined : json['submission_id'],
    };
}

export function AdminImageModelToJSON(value?: AdminImageModel): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'filename': value.filename,
        'published': value.published,
        'submission_id': value.submissionId,
    };
}


