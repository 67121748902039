// tslint:disable
/**
 * Firmabil lekeplass API
 * A description of the API
 *
 * OpenAPI spec version: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ImageBase64,
    ImageBase64FromJSON,
    ImageBase64ToJSON,
    ImageMetadata,
    ImageMetadataFromJSON,
    ImageMetadataToJSON,
    ImageMetadataList,
    ImageMetadataListFromJSON,
    ImageMetadataListToJSON,
    Submission,
    SubmissionFromJSON,
    SubmissionToJSON,
    SubmissionCreate,
    SubmissionCreateFromJSON,
    SubmissionCreateToJSON,
    SubmissionList,
    SubmissionListFromJSON,
    SubmissionListToJSON,
} from '../models';

export interface SubmissionCreateRequest {
    payload: SubmissionCreate;
}

export interface SubmissionGetRequest {
    subId: string;
}

export interface SubmissionUploadRequest {
    subId: string;
    file: Blob;
}

export interface SubmissionUploadbase64Request {
    subId: string;
    payload: Array<ImageBase64>;
}

/**
 * no description
 */
export class SubmissionApi extends runtime.BaseAPI {

    /**
     * create a new playground submission
     * create submission
     */
    async submissionCreateRaw(requestParameters: SubmissionCreateRequest): Promise<runtime.ApiResponse<Submission>> {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling submissionCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/api/v1/submission/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubmissionCreateToJSON(requestParameters.payload),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SubmissionFromJSON(jsonValue));
    }

    /**
     * create a new playground submission
     * create submission
     */
    async submissionCreate(requestParameters: SubmissionCreateRequest): Promise<Submission> {
        const response = await this.submissionCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get one of my submissions
     * get submission
     */
    async submissionGetRaw(requestParameters: SubmissionGetRequest): Promise<runtime.ApiResponse<Submission>> {
        if (requestParameters.subId === null || requestParameters.subId === undefined) {
            throw new runtime.RequiredError('subId','Required parameter requestParameters.subId was null or undefined when calling submissionGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/api/v1/submission/get/{sub_id}`.replace(`{${"sub_id"}}`, encodeURIComponent(String(requestParameters.subId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SubmissionFromJSON(jsonValue));
    }

    /**
     * Get one of my submissions
     * get submission
     */
    async submissionGet(requestParameters: SubmissionGetRequest): Promise<Submission> {
        const response = await this.submissionGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * List my submissions
     * list submission
     */
    async submissionListRaw(): Promise<runtime.ApiResponse<SubmissionList>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/api/v1/submission/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SubmissionListFromJSON(jsonValue));
    }

    /**
     * List my submissions
     * list submission
     */
    async submissionList(): Promise<SubmissionList> {
        const response = await this.submissionListRaw();
        return await response.value();
    }

    /**
     * upload submission
     */
    async submissionUploadRaw(requestParameters: SubmissionUploadRequest): Promise<runtime.ApiResponse<ImageMetadata>> {
        if (requestParameters.subId === null || requestParameters.subId === undefined) {
            throw new runtime.RequiredError('subId','Required parameter requestParameters.subId was null or undefined when calling submissionUpload.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling submissionUpload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const formData = new FormData();
        if (requestParameters.file !== undefined) {
            formData.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/v1/submission/{sub_id}/upload`.replace(`{${"sub_id"}}`, encodeURIComponent(String(requestParameters.subId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formData,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageMetadataFromJSON(jsonValue));
    }

    /**
     * upload submission
     */
    async submissionUpload(requestParameters: SubmissionUploadRequest): Promise<ImageMetadata> {
        const response = await this.submissionUploadRaw(requestParameters);
        return await response.value();
    }

    /**
     * uploadbase64 submission
     */
    async submissionUploadbase64Raw(requestParameters: SubmissionUploadbase64Request): Promise<runtime.ApiResponse<ImageMetadataList>> {
        if (requestParameters.subId === null || requestParameters.subId === undefined) {
            throw new runtime.RequiredError('subId','Required parameter requestParameters.subId was null or undefined when calling submissionUploadbase64.');
        }

        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling submissionUploadbase64.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/api/v1/submission/{sub_id}/uploadimages`.replace(`{${"sub_id"}}`, encodeURIComponent(String(requestParameters.subId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.payload.map(ImageBase64ToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageMetadataListFromJSON(jsonValue));
    }

    /**
     * uploadbase64 submission
     */
    async submissionUploadbase64(requestParameters: SubmissionUploadbase64Request): Promise<ImageMetadataList> {
        const response = await this.submissionUploadbase64Raw(requestParameters);
        return await response.value();
    }

}
