import React, { Component } from "react";


interface Props {
  user: {
    uid: string
    displayName?: string | null
    email?: string | null
  } | null
}
class MainScreen extends Component<Props>{
  render() {
    let { user } = this.props
    if(user == null) {
      return (<div>Not logged in</div>)
    }

    return (
      <div>Main screen: {user && (user.displayName || user.email) || 'hey you must login!!'}</div>
    )
  }
}

export default MainScreen
