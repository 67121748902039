// tslint:disable
/**
 * Firmabil lekeplass API
 * A description of the API
 *
 * OpenAPI spec version: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Submission,
    SubmissionFromJSON,
    SubmissionToJSON,
} from './';

/**
 * SubmissionList media type (default view)
 * @export
 * @interface SubmissionList
 */
export interface SubmissionList {
    /**
     * 
     * @type {Array<Submission>}
     * @memberof SubmissionList
     */
    submissions?: Array<Submission>;
}

export function SubmissionListFromJSON(json: any): SubmissionList {
    return {
        'submissions': !exists(json, 'submissions') ? undefined : (json['submissions'] as Array<any>).map(SubmissionFromJSON),
    };
}

export function SubmissionListToJSON(value?: SubmissionList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'submissions': value.submissions === undefined ? undefined : (value.submissions as Array<any>).map(SubmissionToJSON),
    };
}


