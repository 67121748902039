// tslint:disable
/**
 * Firmabil lekeplass API
 * A description of the API
 *
 * OpenAPI spec version: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface SubmissionCreate
 */
export interface SubmissionCreate {
    /**
     * 
     * @type {number}
     * @memberof SubmissionCreate
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionCreate
     */
    lon: number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionCreate
     */
    title: string;
}

export function SubmissionCreateFromJSON(json: any): SubmissionCreate {
    return {
        'lat': json['lat'],
        'lon': json['lon'],
        'title': json['title'],
    };
}

export function SubmissionCreateToJSON(value?: SubmissionCreate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'lat': value.lat,
        'lon': value.lon,
        'title': value.title,
    };
}


