import React from 'react';
import { Link } from 'react-router-dom';
import { FIRMABIL_URL } from '../config';
import firebase from '../firebase';
import { AdminApi, AdminSubmission, Configuration } from '../firmabil-fetch';
import './SubmissionsList.css';

interface Props { }
interface State {
  submissions: Array<AdminSubmission>
}

class SubmissionsList extends React.Component<Props, State> {

  constructor(props: any) {
    super(props)
    this.state = {
      submissions: [],
    }
  }

  componentDidMount() {
    if (firebase.auth.currentUser == null)
      return

    firebase.auth.currentUser.getIdToken()
      .then(token => listAllSubmissions(token, 100, 0))
      .then(subs => {
        this.setState(Object.assign({}, this.state, {
          submissions: subs.submissions || []
        }))
      })
  }

  render() {
    let { submissions } = this.state;
    return (
      <div>
        <h1>Submission list</h1>
        <div className="submission-list">
          {submissions.map(s => <SubmissionItem key={s.id} s={s} />)}
        </div>
      </div>
    )
  }
}

const SubmissionItem = ({ s }: { s: AdminSubmission }) => (
  <Link className="submission-link" to={"/submissions/" + s.id}>
    <div className="submission-item">
      <h3 className="submission-title">{s.title}</h3>
      <p>{(s.lat||0).toFixed(4) + ", " + (s.lon||0).toFixed(4)}</p>
    </div>
  </Link>
)

const listAllSubmissions = (token: string, limit?: number, offset?: number) => new AdminApi(new Configuration({
  basePath: FIRMABIL_URL,
  apiKey: "Bearer " + token,
})).adminListSubmissions({
  limit: limit,
  offset: offset,
})

export default SubmissionsList
