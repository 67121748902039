// tslint:disable
/**
 * Firmabil lekeplass API
 * A description of the API
 *
 * OpenAPI spec version: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    AdminImageModel,
    AdminImageModelFromJSON,
    AdminImageModelToJSON,
} from './';

/**
 * AdminPlayground media type (default view)
 * @export
 * @interface AdminPlayground
 */
export interface AdminPlayground {
    /**
     * 
     * @type {Date}
     * @memberof AdminPlayground
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof AdminPlayground
     */
    id: string;
    /**
     * 
     * @type {Array<AdminImageModel>}
     * @memberof AdminPlayground
     */
    images?: Array<AdminImageModel>;
    /**
     * 
     * @type {number}
     * @memberof AdminPlayground
     */
    lat?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminPlayground
     */
    lon?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPlayground
     */
    published?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminPlayground
     */
    submissionContributions?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminPlayground
     */
    title: string;
    /**
     * 
     * @type {Date}
     * @memberof AdminPlayground
     */
    updated?: Date;
}

export function AdminPlaygroundFromJSON(json: any): AdminPlayground {
    return {
        'created': !exists(json, 'created') ? undefined : new Date(json['created']),
        'id': json['id'],
        'images': !exists(json, 'images') ? undefined : (json['images'] as Array<any>).map(AdminImageModelFromJSON),
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lon': !exists(json, 'lon') ? undefined : json['lon'],
        'published': !exists(json, 'published') ? undefined : json['published'],
        'submissionContributions': !exists(json, 'submission_contributions') ? undefined : json['submission_contributions'],
        'title': json['title'],
        'updated': !exists(json, 'updated') ? undefined : new Date(json['updated']),
    };
}

export function AdminPlaygroundToJSON(value?: AdminPlayground): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'created': value.created === undefined ? undefined : value.created.toISOString(),
        'id': value.id,
        'images': value.images === undefined ? undefined : (value.images as Array<any>).map(AdminImageModelToJSON),
        'lat': value.lat,
        'lon': value.lon,
        'published': value.published,
        'submission_contributions': value.submissionContributions,
        'title': value.title,
        'updated': value.updated === undefined ? undefined : value.updated.toISOString(),
    };
}


