import { Location } from 'history';
import React from 'react';
import { FIRMABIL_URL, IMAGES_URL } from '../../config';
import firebase from '../../firebase';
import {
  AdminApi,
  AdminPlayground,
  AdminPlaygroundNearbyList,
  AdminSubmission,
  Configuration
} from '../../firmabil-fetch';
import { Button, Grid } from '@material-ui/core';
import './SubmissionDetail.css';
import {
  Image,
  NearbyView,
  SubmissionView
} from './'

interface Props {
  submissionId: string | null
  urlLocation: Location
}

interface State {
  sub?: AdminSubmission | null
  subLoading?: boolean
  subLoadingErr?: any

  nearby?: AdminPlaygroundNearbyList | null
  nearbyLoading?: boolean
  nearbyLoadingErr?: any

  selectedPlaygroundId?: string | null
  selectedPlayground?: AdminPlayground | null
}

class SubmissionDetail extends React.Component<Props, State> {

  constructor(props: any) {
    super(props)

    this.state = {
      sub: null,
      nearby: null,
    }

  }

  componentDidMount() {
    let { submissionId } = this.props;
    console.log("mount search", this.props.urlLocation.search)
    if (firebase.auth.currentUser == null || submissionId == null) return
    firebase.auth.currentUser.getIdToken()
      .then(token => {

        let api = new AdminApi(new Configuration({
          basePath: FIRMABIL_URL,
          apiKey: "Bearer " + token,
        }))

        this.setState(Object.assign<State, State, State>({}, this.state, {
          subLoading: true,
          subLoadingErr: null,
        }))
        return api.adminGetSubmission({ id: submissionId || "" })
          .then(sub => {
            this.setState(Object.assign<State, State, State>({}, this.state, {
              sub: sub,
              subLoading: false,
              subLoadingErr: null,
              nearbyLoading: true,
            }))

            return api.adminNearbyPlaygrounds({
              lat: sub.lat || 0,
              lon: sub.lon || 0,
              radius: 1000,
            })
              .then(nearby => {
                this.setState(Object.assign<State, State, State>({}, this.state, {
                  nearby: nearby,
                  nearbyLoading: false,
                }))
              }).catch(err => {
                this.setState(Object.assign<State, State, State>({}, this.state, {
                  nearbyLoading: false,
                  nearbyLoadingErr: err,
                }))
              })
          }).catch(err => {
            this.setState(Object.assign<State, State, State>({}, this.state, {
              subLoading: false,
              subLoadingErr: err
            }))
          })
      })
  }


  render() {
    let { submissionId } = this.props;
    let {
      sub, subLoading, subLoadingErr,
      nearby, nearbyLoading, nearbyLoadingErr,
      selectedPlayground, selectedPlaygroundId
    } = this.state;
    if (submissionId == null) return <div>No submission id :(</div>
    const onNearbySelected = (id: string, pg: AdminPlayground | null) => {
      console.log("this is hash", this.props.urlLocation.search)
      const params = new URLSearchParams(this.props.urlLocation.search)
      console.log("params", params, "values", params.values(), "playground_id", params.get("playground_id"))
      window.history.replaceState(window.history.state, "", "?playground_id=" + id)

      this.setState(Object.assign<State, State, State>({}, this.state, {
        selectedPlaygroundId: id,
        selectedPlayground: pg,
      }))
    }

    //let showNearby = nearby && (nearby.playgrounds ||[]).reduce((acc, curr) => acc && curr.title != (sub && sub.title || "") ,true)

    let showNearby = true

    let subCoords = (sub && { lat: sub.lat || 0, lon: sub.lon || 0 }) || null

    return (
      <div>

        <div>Submission Detail {"ID: " + submissionId}</div>

        <div style={{flexGrow: 1, overflow: 'hidden', backgroundColor: '#fff'}}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            spacing={0}>

            <Grid item xs style={{padding: 10}}>
              <SubmissionView
                selectedPlaygroundID={selectedPlaygroundId || null}
                loading={subLoading === true}
                loadingErr={subLoadingErr}
                sub={sub || null} />
            </Grid>

            <Grid item xs style={{padding: 10}}>
              {
                showNearby &&
                <Button
                  style={{background:'#04040444'}}
                  onClick={() => {
                    window.confirm("Vil du lage ny lekeplass?") &&
                    firebase.auth.currentUser &&
                    firebase.auth.currentUser.getIdToken()
                    .then(token => {
                      new AdminApi(new Configuration({ basePath: FIRMABIL_URL, apiKey: "Bearer " + token }))
                      .adminCreatePlaygroundFromSubmission({submissionId: submissionId||""})
                      .then(() => window.location.href=window.location.href ) // refresh?
                      .catch((err)=>console.log("failed to create playground from submission",err))
                    })
                  }}>
                  Create from submission
                </Button>
              }
              <NearbyView
                onselect={onNearbySelected.bind(this)}
                selectedId={selectedPlaygroundId || null}
                nearby={nearby || null}
                loading={nearbyLoading === true}
                subCoordinates={subCoords}/>
            </Grid>

            <Grid item xs style={{padding: 10}}>
              <PlaygroundView playground={selectedPlayground || null} />
            </Grid>

          </Grid>
        </div>
      </div>
    )
  }
}

interface PlaygroundViewProps {
  loading?: boolean
  playground: AdminPlayground | null
}
const PlaygroundView = ({ loading, playground }: PlaygroundViewProps) => {
  if (loading) return <div>Loading playground...</div>
  if (playground == null) return <div>No playground selected</div>
  let createdString = (playground.created && playground.created.toISOString()) || "null"
  let renderedImages = (playground.images || []).map(image => <Image key={image.filename} src={IMAGES_URL + "/image/sub/w_300/" + image.submissionId + "/" + image.filename} />)

  return (
    <div>
      <p>{playground.title}</p>
      <p>{createdString + " - " + playground.id}</p>
      <div>
        {renderedImages}
      </div>
    </div>
  )
}


export default SubmissionDetail
