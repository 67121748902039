// tslint:disable
/**
 * Firmabil lekeplass API
 * A description of the API
 *
 * OpenAPI spec version: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ImageMetadata,
    ImageMetadataFromJSON,
    ImageMetadataToJSON,
} from './';

/**
 * Metadata about images (default view)
 * @export
 * @interface ImageMetadataList
 */
export interface ImageMetadataList {
    /**
     * 
     * @type {Array<ImageMetadata>}
     * @memberof ImageMetadataList
     */
    images?: Array<ImageMetadata>;
}

export function ImageMetadataListFromJSON(json: any): ImageMetadataList {
    return {
        'images': !exists(json, 'images') ? undefined : (json['images'] as Array<any>).map(ImageMetadataFromJSON),
    };
}

export function ImageMetadataListToJSON(value?: ImageMetadataList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'images': value.images === undefined ? undefined : (value.images as Array<any>).map(ImageMetadataToJSON),
    };
}


