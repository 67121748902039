// tslint:disable
/**
 * Firmabil lekeplass API
 * A description of the API
 *
 * OpenAPI spec version: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ImageBase64
 */
export interface ImageBase64 {
    /**
     * 
     * @type {string}
     * @memberof ImageBase64
     */
    base64: string;
    /**
     * 
     * @type {string}
     * @memberof ImageBase64
     */
    mime?: string;
}

export function ImageBase64FromJSON(json: any): ImageBase64 {
    return {
        'base64': json['base64'],
        'mime': !exists(json, 'mime') ? undefined : json['mime'],
    };
}

export function ImageBase64ToJSON(value?: ImageBase64): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'base64': value.base64,
        'mime': value.mime,
    };
}


