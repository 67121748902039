// tslint:disable
/**
 * Firmabil lekeplass API
 * A description of the API
 *
 * OpenAPI spec version: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminPlayground,
    AdminPlaygroundFromJSON,
    AdminPlaygroundToJSON,
    AdminPlaygroundList,
    AdminPlaygroundListFromJSON,
    AdminPlaygroundListToJSON,
    AdminPlaygroundNearbyList,
    AdminPlaygroundNearbyListFromJSON,
    AdminPlaygroundNearbyListToJSON,
    AdminSubmission,
    AdminSubmissionFromJSON,
    AdminSubmissionToJSON,
    AdminSubmissionList,
    AdminSubmissionListFromJSON,
    AdminSubmissionListToJSON,
} from '../models';

export interface AdminAttachPlaygroundImageRequest {
    filename: string;
    id: string;
    published: boolean;
    submissionId: string;
}

export interface AdminCreatePlaygroundFromSubmissionRequest {
    submissionId: string;
}

export interface AdminGetPlaygroundRequest {
    id: string;
}

export interface AdminGetSubmissionRequest {
    id: string;
}

export interface AdminListPlaygroundsRequest {
    limit?: number;
    offset?: number;
}

export interface AdminListSubmissionsRequest {
    limit?: number;
    offset?: number;
}

export interface AdminNearbyPlaygroundsRequest {
    lat: number;
    lon: number;
    radius?: number;
}

export interface AdminSetPlaygroundImagePublishedRequest {
    filename: string;
    id: string;
    published: boolean;
    submissionId: string;
}

export interface AdminSetPlaygroundPublishedRequest {
    id: string;
    published: boolean;
}

/**
 * no description
 */
export class AdminApi extends runtime.BaseAPI {

    /**
     * Required security scopes:   * `admin:true`
     * attach-playground-image admin
     */
    async adminAttachPlaygroundImageRaw(requestParameters: AdminAttachPlaygroundImageRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.filename === null || requestParameters.filename === undefined) {
            throw new runtime.RequiredError('filename','Required parameter requestParameters.filename was null or undefined when calling adminAttachPlaygroundImage.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminAttachPlaygroundImage.');
        }

        if (requestParameters.published === null || requestParameters.published === undefined) {
            throw new runtime.RequiredError('published','Required parameter requestParameters.published was null or undefined when calling adminAttachPlaygroundImage.');
        }

        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling adminAttachPlaygroundImage.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filename !== undefined) {
            queryParameters['filename'] = requestParameters.filename;
        }

        if (requestParameters.published !== undefined) {
            queryParameters['published'] = requestParameters.published;
        }

        if (requestParameters.submissionId !== undefined) {
            queryParameters['submission-id'] = requestParameters.submissionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/adminapi/v1/playground/{id}/attach-image`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Required security scopes:   * `admin:true`
     * attach-playground-image admin
     */
    async adminAttachPlaygroundImage(requestParameters: AdminAttachPlaygroundImageRequest): Promise<void> {
        await this.adminAttachPlaygroundImageRaw(requestParameters);
    }

    /**
     * Required security scopes:   * `admin:true`
     * create-playground-from-submission admin
     */
    async adminCreatePlaygroundFromSubmissionRaw(requestParameters: AdminCreatePlaygroundFromSubmissionRequest): Promise<runtime.ApiResponse<AdminPlayground>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling adminCreatePlaygroundFromSubmission.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.submissionId !== undefined) {
            queryParameters['submission-id'] = requestParameters.submissionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/adminapi/v1/create-playground-from-submission`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminPlaygroundFromJSON(jsonValue));
    }

    /**
     * Required security scopes:   * `admin:true`
     * create-playground-from-submission admin
     */
    async adminCreatePlaygroundFromSubmission(requestParameters: AdminCreatePlaygroundFromSubmissionRequest): Promise<AdminPlayground> {
        const response = await this.adminCreatePlaygroundFromSubmissionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Required security scopes:   * `admin:true`
     * get-playground admin
     */
    async adminGetPlaygroundRaw(requestParameters: AdminGetPlaygroundRequest): Promise<runtime.ApiResponse<AdminPlayground>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminGetPlayground.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/adminapi/v1/playground/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminPlaygroundFromJSON(jsonValue));
    }

    /**
     * Required security scopes:   * `admin:true`
     * get-playground admin
     */
    async adminGetPlayground(requestParameters: AdminGetPlaygroundRequest): Promise<AdminPlayground> {
        const response = await this.adminGetPlaygroundRaw(requestParameters);
        return await response.value();
    }

    /**
     * Required security scopes:   * `admin:true`
     * get-submission admin
     */
    async adminGetSubmissionRaw(requestParameters: AdminGetSubmissionRequest): Promise<runtime.ApiResponse<AdminSubmission>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminGetSubmission.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/adminapi/v1/submission/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminSubmissionFromJSON(jsonValue));
    }

    /**
     * Required security scopes:   * `admin:true`
     * get-submission admin
     */
    async adminGetSubmission(requestParameters: AdminGetSubmissionRequest): Promise<AdminSubmission> {
        const response = await this.adminGetSubmissionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Required security scopes:   * `admin:true`
     * list-playgrounds admin
     */
    async adminListPlaygroundsRaw(requestParameters: AdminListPlaygroundsRequest): Promise<runtime.ApiResponse<AdminPlaygroundList>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/adminapi/v1/list-playgrounds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminPlaygroundListFromJSON(jsonValue));
    }

    /**
     * Required security scopes:   * `admin:true`
     * list-playgrounds admin
     */
    async adminListPlaygrounds(requestParameters: AdminListPlaygroundsRequest): Promise<AdminPlaygroundList> {
        const response = await this.adminListPlaygroundsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Required security scopes:   * `admin:true`
     * list-submissions admin
     */
    async adminListSubmissionsRaw(requestParameters: AdminListSubmissionsRequest): Promise<runtime.ApiResponse<AdminSubmissionList>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/adminapi/v1/list-submissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminSubmissionListFromJSON(jsonValue));
    }

    /**
     * Required security scopes:   * `admin:true`
     * list-submissions admin
     */
    async adminListSubmissions(requestParameters: AdminListSubmissionsRequest): Promise<AdminSubmissionList> {
        const response = await this.adminListSubmissionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Required security scopes:   * `admin:true`
     * nearby-playgrounds admin
     */
    async adminNearbyPlaygroundsRaw(requestParameters: AdminNearbyPlaygroundsRequest): Promise<runtime.ApiResponse<AdminPlaygroundNearbyList>> {
        if (requestParameters.lat === null || requestParameters.lat === undefined) {
            throw new runtime.RequiredError('lat','Required parameter requestParameters.lat was null or undefined when calling adminNearbyPlaygrounds.');
        }

        if (requestParameters.lon === null || requestParameters.lon === undefined) {
            throw new runtime.RequiredError('lon','Required parameter requestParameters.lon was null or undefined when calling adminNearbyPlaygrounds.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.lat !== undefined) {
            queryParameters['lat'] = requestParameters.lat;
        }

        if (requestParameters.lon !== undefined) {
            queryParameters['lon'] = requestParameters.lon;
        }

        if (requestParameters.radius !== undefined) {
            queryParameters['radius'] = requestParameters.radius;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/adminapi/v1/nearby-playgrounds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminPlaygroundNearbyListFromJSON(jsonValue));
    }

    /**
     * Required security scopes:   * `admin:true`
     * nearby-playgrounds admin
     */
    async adminNearbyPlaygrounds(requestParameters: AdminNearbyPlaygroundsRequest): Promise<AdminPlaygroundNearbyList> {
        const response = await this.adminNearbyPlaygroundsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Required security scopes:   * `admin:true`
     * set-playground-image-published admin
     */
    async adminSetPlaygroundImagePublishedRaw(requestParameters: AdminSetPlaygroundImagePublishedRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.filename === null || requestParameters.filename === undefined) {
            throw new runtime.RequiredError('filename','Required parameter requestParameters.filename was null or undefined when calling adminSetPlaygroundImagePublished.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminSetPlaygroundImagePublished.');
        }

        if (requestParameters.published === null || requestParameters.published === undefined) {
            throw new runtime.RequiredError('published','Required parameter requestParameters.published was null or undefined when calling adminSetPlaygroundImagePublished.');
        }

        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling adminSetPlaygroundImagePublished.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filename !== undefined) {
            queryParameters['filename'] = requestParameters.filename;
        }

        if (requestParameters.published !== undefined) {
            queryParameters['published'] = requestParameters.published;
        }

        if (requestParameters.submissionId !== undefined) {
            queryParameters['submission-id'] = requestParameters.submissionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/adminapi/v1/playground/{id}/set-image-published`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Required security scopes:   * `admin:true`
     * set-playground-image-published admin
     */
    async adminSetPlaygroundImagePublished(requestParameters: AdminSetPlaygroundImagePublishedRequest): Promise<void> {
        await this.adminSetPlaygroundImagePublishedRaw(requestParameters);
    }

    /**
     * Required security scopes:   * `admin:true`
     * set-playground-published admin
     */
    async adminSetPlaygroundPublishedRaw(requestParameters: AdminSetPlaygroundPublishedRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminSetPlaygroundPublished.');
        }

        if (requestParameters.published === null || requestParameters.published === undefined) {
            throw new runtime.RequiredError('published','Required parameter requestParameters.published was null or undefined when calling adminSetPlaygroundPublished.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.published !== undefined) {
            queryParameters['published'] = requestParameters.published;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // jwt authentication
        }

        const response = await this.request({
            path: `/adminapi/v1/playground/{id}/set-published`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Required security scopes:   * `admin:true`
     * set-playground-published admin
     */
    async adminSetPlaygroundPublished(requestParameters: AdminSetPlaygroundPublishedRequest): Promise<void> {
        await this.adminSetPlaygroundPublishedRaw(requestParameters);
    }

}
