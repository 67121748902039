import React, { Component, useState } from 'react';
import { BrowserRouter, Link, Route, RouteComponentProps, Switch } from 'react-router-dom';
import './App.css';
import firebase from './firebase';
import { MainScreen, MySubmissionsDetail, MySubmissionsList, PlaygroundDetail, PlaygroundsScreen, SubmissionDetail, SubmissionsList } from './screens';

interface FirebaseUser {
  uid: string
  displayName: string | null
  email: string | null
  providerId: string
  scope?: Array<string>
}
interface State {
  authInitialized: boolean
  authenticated: boolean
  firebaseUser: FirebaseUser | null
  firebaseToken: string | null
}


const loginGoogle = (): Promise<any> => firebase.auth.signInWithPopup(firebase.GoogleAuthProvider)
  .then(user => { console.log("Signed in?", user); return user })

const loginEmail = (email: string, password: string): Promise<any> => firebase.auth.signInWithEmailAndPassword(email, password)
  .then(user => { console.log("Signed in?", user); return user })

const isAuthorized = (token: string | null, requiredScopes: Array<string>): boolean => {
  if (token == null || token.length == 0) {
    return false
  }

  try {
    const [, payload] = token.split(".")
    let parsed = JSON.parse(atob(payload))
    let scopes: Array<string> = parsed['scope'] || parsed['scopes'] || []
    return requiredScopes.reduce((a: boolean, scope: string) => a && scopes.indexOf(scope) > -1, true)
  } catch (err) {
    console.error("failed to decode token", err)
    return false
  }
  return false
}


interface RenderLoginProps {
  authInitialized: boolean
}

const RenderLogin = ({ authInitialized }: RenderLoginProps) => {
  let [email, setEmail] = useState<string>("")
  let [password, setPassword] = useState<string>("")

  if (!authInitialized) {
    return (<div>Loading...</div>)
  }

  return (
    <div>
      <div>
        <h2>Welcome to login</h2>
        <button onClick={() => loginGoogle().catch((err => {
          console.error("failed to login with google")
          alert("Login with google failed, check logs")
        }))}>Login Google</button>
      </div>
      <div>
        <form onSubmit={(e) => { e.preventDefault(); }}>
          <label>Email
          <input type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </label>
          <label>Password
            <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </label>
          <input type="submit" onClick={(e) => {
            loginEmail(email, password).catch(err => {
              console.error("login error", err)
              alert("Failed to login, please check logs")
            })
          }} />
        </form>
      </div>
    </div>
  )
}



interface HeaderProps {
  user: FirebaseUser | null
  scopes?: Array<String>
}

const Header = ({ user, scopes }: HeaderProps) => (
  <header className="App-header">
    <div className="App-menus">
      <Link to={"/"} className="App-link App-title">
        <div>{"Admin & Lek"}</div>
      </Link>
      <Link to={"/playgrounds"} className="App-link">
        <div>{"Lekeplasser"}</div>
      </Link>
      <Link to={"/submissions"} className="App-link">
        <div>{"Innsendinger"}</div>
      </Link>
    </div>
    <div className="loggedInButton">
      <LoggedIn user={user} />
    </div>
  </header>
)

interface LoggedInProps {
  user?: FirebaseUser | null
}
const LoggedIn = ({ user }: LoggedInProps) => {
  if (!user) return <div />;
  let name = user.displayName || user.email

  return (
    <div>
      <div>{name}</div>
      <button onClick={() => firebase.auth.signOut()}>Logg ut</button>
    </div>
  );

}


class App extends Component<any, State> {
  unsubscribeAuth?: () => void

  constructor(props: any) {
    super(props)
    this.state = {
      authInitialized: false,
      authenticated: false,
      firebaseUser: null,
      firebaseToken: null,
    }
  }
  componentDidMount() {
    this.unsubscribeAuth = firebase.auth.onAuthStateChanged((user) => {
      if (user == null) {
        this.setState({
          authInitialized: true,
          authenticated: user != null,
          firebaseUser: user,
          firebaseToken: null,
        })
      } else {
        user.getIdToken().then(token => {
          this.setState({
            authInitialized: true,
            authenticated: true,
            firebaseUser: user,
            firebaseToken: token,
          })
        }).catch(err => {
          this.setState({
            authInitialized: true,
            authenticated: false,
            firebaseUser: null,
            firebaseToken: null,
          })
        })
      }
    })
  }

  componentWillUnmount() {
    this.unsubscribeAuth != null && this.unsubscribeAuth()
  }

  renderBody(component: any) {
    return (
      <BrowserRouter>
        <div className="App">
          <Header user={this.state.firebaseUser || null} />

          {component}
        </div>
      </BrowserRouter>
    )
  }

  render() {
    let { authInitialized, firebaseUser, firebaseToken } = this.state
    if (firebaseUser == null) {
      return <RenderLogin authInitialized={authInitialized} />
    }

    if (!isAuthorized(firebaseToken, ["admin:true"])) {
      return this.renderBody((<div>{"You don't have required permissions"}</div>))
    }

    return (
      <BrowserRouter>
        <div className="App">
          <Header user={firebaseUser} />
          <div className="content">
            <Switch>
              <Route
                exact path="/"
                component={() => <MainScreen user={firebaseUser} />} />
              <Route
                exact path="/playgrounds/:id"
                component={({ match }: RouteComponentProps<{ id?: string }>) => <PlaygroundDetail playgroundId={match == null ? null : match.params.id} />} />
              <Route
                exact path="/playgrounds"
                component={() => <PlaygroundsScreen {...this.props} />} />
              <Route
                exact path="/my-submissions/:id"
                component={({ match }: RouteComponentProps<{ id?: string }>) => <MySubmissionsDetail submissionId={match == null ? null : match.params.id || null} />} />
              <Route
                exact path="/my-submissions"
                component={() => <MySubmissionsList {...this.props} />} />
              <Route
                exact path="/submissions/:id"
                component={({ match, location }: RouteComponentProps<{ id?: string }>) => <SubmissionDetail urlLocation={location} submissionId={match == null ? null : match.params.id || null} />} />
              <Route
                exact path="/submissions"
                component={(props: any) => <SubmissionsList {...props} />} />

            </Switch>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
