import React from 'react'

interface ImageProps {
  src: string,
  href?: string | null,
  onclick?: Function
}

export default class Image extends React.Component<ImageProps> {
  render() {
    let { src, onclick, href } = this.props
    let img = <img src={src} onClick={() => onclick && onclick()} />
    return href == null ? img : <a href={href}>{img}</a>
  }
}
