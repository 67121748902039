// tslint:disable
/**
 * Firmabil lekeplass API
 * A description of the API
 *
 * OpenAPI spec version: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * AdminSubmission media type (default view)
 * @export
 * @interface AdminSubmission
 */
export interface AdminSubmission {
    /**
     * 
     * @type {Date}
     * @memberof AdminSubmission
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof AdminSubmission
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminSubmission
     */
    imagePaths?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AdminSubmission
     */
    lat?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminSubmission
     */
    lon?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminSubmission
     */
    playgroundsContributedTo?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminSubmission
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSubmission
     */
    userId: string;
}

export function AdminSubmissionFromJSON(json: any): AdminSubmission {
    return {
        'created': !exists(json, 'created') ? undefined : new Date(json['created']),
        'id': json['id'],
        'imagePaths': !exists(json, 'image_paths') ? undefined : json['image_paths'],
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lon': !exists(json, 'lon') ? undefined : json['lon'],
        'playgroundsContributedTo': !exists(json, 'playgrounds_contributed_to') ? undefined : json['playgrounds_contributed_to'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'userId': json['user_id'],
    };
}

export function AdminSubmissionToJSON(value?: AdminSubmission): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'created': value.created === undefined ? undefined : value.created.toISOString(),
        'id': value.id,
        'image_paths': value.imagePaths,
        'lat': value.lat,
        'lon': value.lon,
        'playgrounds_contributed_to': value.playgroundsContributedTo,
        'title': value.title,
        'user_id': value.userId,
    };
}


