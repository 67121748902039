import React from 'react'
import { AdminPlayground, AdminPlaygroundNearbyList } from '../../firmabil-fetch';

const Map = require('pigeon-maps')

interface Coordinates {
  lat: number
  lon: number
}

interface NearbyViewProps {
  subCoordinates?: Coordinates | null
  nearby: AdminPlaygroundNearbyList | null
  loading?: boolean
  selectedId?: string | null
  onselect: (id: string, pg: AdminPlayground) => void
}

export default class NearbyView extends React.Component<NearbyViewProps> {
  render() {
    let { loading, nearby, onselect, subCoordinates, selectedId } = this.props

    if (!!loading) return <div>Finding nearby playgrounds...</div>

    let nearbyPlaygrounds = (nearby && nearby.playgrounds) || []
    let { lat, lon } = subCoordinates || nearby || { lat: 0, lon: 0 };
    let pgSelected = (selectedId != null && nearbyPlaygrounds.filter(pg => selectedId == pg.id)) || []
    let pgUnselected = nearbyPlaygrounds.filter(pg => selectedId != pg.id) || []

    return (
      <div>
        <h3>Steder i nærheten</h3>

        <div className="nearby-map">
          <Map
            center={[lat, lon]}
            zoom={15}
            maxZoom={16}
            minZoom={2}
            provider={providerOSM}
          >
            {subCoordinates && <SubmissionMarker color={'#0044ffaa'} anchor={[subCoordinates.lat, subCoordinates.lon]} />}
            {pgSelected.map(pg => <Marker key={pg.id} color="#ff0402d0" size="20px" anchor={[pg.lat, pg.lon]} />)}
            {pgUnselected.map(pg => <Marker key={pg.id} color="#bb1122aa" size="15px" onClick={() => onselect(pg.id, pg)} anchor={[pg.lat, pg.lon]} />)}
          </Map>
        </div>

        <ul className="nearby-list">
          {nearbyPlaygrounds.map(pg => {
            return (
              <li
                className={selectedId != null && pg.id == selectedId && "selected" || ""} onClick={() => onselect(pg.id, pg)}
                key={pg.id}>
                <p className="title">{pg.title}</p>
                <p className="subtitle">{pg.lat + ", " + pg.lon}</p>
              </li>
            )
          })}
        </ul>

      </div>
    )
  }
}

function providerOSM (x:number, y:number, z:number):string {
  const s = String.fromCharCode(97 + (x + y + z) % 3)
  return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`
}


interface MarkerProps {
  lat: number
  lon: number
  left?: number
  top?: number
  anchor?: Array<number>
  style?: any
  color?: string
  children?: any
  size?: string
  mapState: any
  onClick?: Function
  latLngToPixel?: (latlng: Array<number>, center?: Array<number>, zoom?: number) => Array<number>
}

const Marker = (props: MarkerProps & any, state: any) => {
  let { lat, lon, style, children, color, size, left, top } = props;
  let [px, py] = props.latLngToPixel([lat, lon], props.mapState.center, props.mapState.zoom)

  return (
    <div
      onClick={() => { props.onClick && props.onClick() }}
      style={{
        transform: `translate(-50%,-100%) translate(${left}px, ${top}px) rotateZ(-45deg) rotateX(20deg)`,
        borderRadius: '50% 50% 50% 0',
        width: size || '40px',
        height: size || '40px',
        background: color || '#ed1f34aa',
        position: 'absolute',
        top: `calc(${top + "+" + px + "px"})`,
        left: `calc(${left + "+" + py + "px"})`,
        WebkitBoxShadow: '-1px 1px 4px rgba(0, 0, 0, .5)',
        MozBoxShadow: '-1px 1px 4px rgba(0, 0, 0, .5)',
        boxShadow: '-1px 1px 4px rgba(0, 0, 0, .5)',
        ...(style || {}),
      }}>{children}</div>
  )
}

const SubmissionMarker = (props: MarkerProps & any, state: any) => {
  let { lat, lon, style, children, color, size, left, top } = props;
  let [px, py] = props.latLngToPixel([lat, lon], props.mapState.center, props.mapState.zoom)

  return (
    <div
      onClick={() => { props.onClick && props.onClick() }}
      style={{
        transform: `translate(-50%, -50%) translate(${left}px, ${top}px)`,
        borderRadius: '50% 50% 50% 50%',
        width: size || '25px',
        height: size || '25px',
        background: color || '#1111ffAA',
        position: 'absolute',
        top: `calc(${top + "px +" + px + "px"})`,
        left: `calc(${left + "px +" + py + "px"})`,
        WebkitBoxShadow: '-1px 1px 4px rgba(0, 0, 0, .5)',
        MozBoxShadow: '-1px 1px 4px rgba(0, 0, 0, .5)',
        boxShadow: '-1px 1px 4px rgba(0, 0, 0, .5)',
        ...(style || {}),
      }}>{children}</div>
  )
}
