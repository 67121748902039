// tslint:disable
/**
 * Firmabil lekeplass API
 * A description of the API
 *
 * OpenAPI spec version: v0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * Metadata about images (default view)
 * @export
 * @interface ImageMetadata
 */
export interface ImageMetadata {
    /**
     * Relative path of the image
     * @type {string}
     * @memberof ImageMetadata
     */
    basepath: string;
    /**
     * Image filename
     * @type {string}
     * @memberof ImageMetadata
     */
    filename: string;
    /**
     * list of image variants
     * @type {Array<string>}
     * @memberof ImageMetadata
     */
    variants?: Array<string>;
}

export function ImageMetadataFromJSON(json: any): ImageMetadata {
    return {
        'basepath': json['basepath'],
        'filename': json['filename'],
        'variants': !exists(json, 'variants') ? undefined : json['variants'],
    };
}

export function ImageMetadataToJSON(value?: ImageMetadata): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'basepath': value.basepath,
        'filename': value.filename,
        'variants': value.variants,
    };
}


