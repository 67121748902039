import React, { Component } from "react";
import { FIRMABIL_URL, IMAGES_URL } from "../config";
import firebase from '../firebase';
import { Configuration, AdminApi, AdminPlayground } from "../firmabil-fetch";
import './PlaygroundDetail.css';

interface Props {
  playgroundId?: string | null
}

interface State {
  loading: boolean
  playground: AdminPlayground | null
  error?: boolean
  errMsg?: string
}

class PlaygroundDetail extends Component<Props, State> {

  constructor(props: any) {
    super(props)
    this.state = {
      loading: false,
      playground: null,
      error: false,
      errMsg: "",
    }
  }

  componentDidMount() {
    if (firebase.auth.currentUser == null) return;
    let { playgroundId } = this.props;
    if (playgroundId == null) {
      this.setState({
        loading: false,
        playground: null,
        error: true,
        errMsg: "No Playground ID supplied",
      })
      return
    }
    let pgid: string = playgroundId
    firebase.auth.currentUser.getIdToken()
      .then(token => fetchPlayground(pgid, token))
      .then(pg => this.setState({
        loading: false,
        playground: pg,
        error: pg == null,
        errMsg: "",
      })).catch(err => {
        console.log("Got err", err)
        this.setState({
          loading: false,
          playground: null,
          error: true,
          errMsg: err && err.status + " " + err.statusText,
        })
      })

  }

  render() {
    let { loading, playground, error, errMsg } = this.state;
    return (
      <div>
        {loading && "Loading..."}
        {error && "Error: " + errMsg}
        {playground != null && (
          <div>
            <h1>{playground.title}</h1>
            <p>{playground.lat + "," + playground.lon}</p>
            <div className="image-list">
              {renderImages(playground)}
            </div>
          </div>
        )}
        <pre className="preview-json">{JSON.stringify(playground, null, "  ")}</pre>
      </div>
    )
  }
}

const renderImages = (p: AdminPlayground) => (p.images || []).map(i => (
  <Image key={i.filename}
    src={IMAGES_URL + "/image/sub/w_300/" + i.submissionId + "/" + i.filename}
    href={IMAGES_URL + "/image/sub/w_1200/" + i.submissionId + "/" + i.filename}
  />))

const Image = ({ src, href }: { src: string, href?: string | null }): any => {
  let img = <img src={src} />
  return href == null ? img : <a href={href}>{img}</a>
}

const fetchPlayground = (id: string, token: string) => new AdminApi(new Configuration({
  basePath: FIRMABIL_URL,
  apiKey: "Bearer " + token,
})).adminGetPlayground({ id: id })


export default PlaygroundDetail
